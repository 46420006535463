/* small devices (tablets) */
@media (max-width: 767px) {
  
  .content-wrapper > .content {
      padding: 0 0.1rem;
  }
  .modal-body{
    padding-right: 5px;
    padding-left: 5px;
  }

  .modal-dialog{
    margin-right: 3px;
    margin-left: 3px;
  }
  .form-control{
    padding-right: 5px;
    padding-left: 5px;
  }
  .form-group {
      margin-bottom: 10px!important;
  }

  .page-tool {
    text-align    : left;
    margin-bottom : 10px;
  }
  

}/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  
  .padding-forminput{
    padding-right: 20px;
  }
  
  .form-group {
    margin-bottom: 10px!important;
}

}

/* Large devices (desktops, 992px and up)  */
@media (min-width: 992px) and (max-width: 1199.98px) { 
  
  .padding-forminput{
    padding-right: 30px;
  }
  
  .form-group {
    margin-bottom: 10px!important;
}

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

  .padding-forminput{
    padding-right: 40px;
  }

 }