::selection {
  background: #929292;
  color: #FFFFFF;
  }
::-moz-selection {
  background: #929292;
  color: #FFFFFF;
  }
::-webkit-selection {
  background: #929292;
  color: #FFFFFF;
  }

.slide-nav-blue{
    color: white;
    background-color: #304e7a;
    border-radius: 50%;
}
.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active{
    background-color: #2e5d91;
    color: #fff;
}
[class*=sidebar-dark-]{
  background-color: #1a4066;
}
.bg-softblue{
  background-color: #527da9;
}
.bg-linearblue{
  color: white;
  background: linear-gradient(90deg, rgb(23 80 137) 1%, rgb(68 119 171) 45%, rgba(88,166,244,1) 100%, rgba(121,177,235,1) 100%);
}
.navbar-light .navbar-nav .nav-link {
  color: #ebe3e3;
}
.navbar-light .navbar-nav .nav-link:focus {
  color: white;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: white;
}
.table {
  width: 100%!important;
}
.table td, .table th {
  padding: 0.3rem !important;
}
.card-header {
  background-color: #ebebeb;
}
.page-item.active .page-link {
  background-color: #527da9;
  border-color: #476f99;
}
.page-link{
  color: #476f99;
  padding: 0.3rem 0.5rem!important;
}
.btn{
  cursor: pointer;
}
.btn-menu{
    cursor: pointer;
    font-size: 20px;
    margin-right: 10px;
}
.btn-back{
  background-color: #959996 !important;
  border: 1px solid #d5d4d4;
  color: white!important;
  cursor: pointer;
}
.btn-back:hover{
  background-color: #a5a8a6;
  border: 1px solid #d5d4d4;
  color: white!important;
}
.btn-primary{
  background-color: #527da9;
  border-color: #4b6c8f;
  color: #f5f0f0!important;
}
.btn-primary:active{
  background-color: #132a40 !important;
  border-color: #4b6c8f;
  color: #f5f0f0!important;
}

.btn-danger{
  color: #f5f0f0!important;
}
.btn-danger:hover{
  background-color: #eb4b5a!important;
}
.btn-primary:hover{
  background-color: #6594c4;
  border-color: #4b6c8f;
}
.error{
  color: red;
  font-weight: 400!important;
}

.btn-success{
  color: #fff;
  background-color: #448f55;
  border-color: #276234;
}
.btn-success:hover{
  color: #fff;
  background-color: #49b862;
  border-color: #276234;
}
.btn-table{
  border-radius: 15px;
  line-height: 1;
  margin-left: 5px;
}
.card-header-form{
  padding: 0.2rem 1rem;
}
.card-header-form .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0px;
}

.form-control.is-invalid, .was-validated .form-control:invalid{
  background-image:none;
}

.form-control{
  border-radius: 0px;  
  border-top: none;
  border-bottom: 1px solid #ced4da;
  border-right: none;
  border-left: none;
}
.padding-forminput{
  padding-right: 7.5px;
}
.padding-left-remove{
  padding-left: 0!important;
}
.padding-right-remove{
  padding-right: 0!important;
}
.padding-top-remove{
  padding-top: 0!important;
}
.padding-bottom-remove{
  padding-bottom: 0!important;
}
.padding-remove{
  padding: 0px 0px !important;
}

.margin-left-remove{
  margin-left: 0!important;
}
.margin-right-remove{
  margin-right: 0!important;
}
.margin-top-remove{
  margin-top: 0!important;
}
.margin-bottom-remove{
  margin-bottom: 0!important;
}
.margin-remove{
  margin: 0px 0px !important;
}

.border-radius-remove{
  border-radius: 0px;
}
.modal-footer{
  padding: 0.3rem;
  background-color: #ebebeb;
}
.modal-content{
  border-radius: 0px;
}
.modal-header {
  padding: 0.4rem;
  border-bottom: 1px solid #e9ecef;
  background-color: #7ba8d7;
  border-radius: 0px;
}
.swal-modal{
  border-radius: 0px;
}
.loadingoverlay_fa{
  color:rgb(33 101 141)!important;
}
table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, 
table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, 
table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, 
table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, 
table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after{
  bottom: 0.4em;
}
.select2-results {
  background-color: #ffffff;
}

.select2-container .select2-selection--single{
  height: 35px!important;
}
.select2-container--default{
  max-width: 100%!important;
}
.select2-container--open{
  width: auto;
}
.full-width{
  width: 100%!important;
}
.full-width > .select2-container--default{
  width: 100%!important;
}


td > select {
  border     : none;
  background : none;
}

.bootstrap-datetimepicker-widget.dropdown-menu{
    display: block;
    margin: 0px 0;
    padding: 2px;
    width: 12rem;
    z-index: 999;
}

.bootstrap-datetimepicker-widget table td{
    height: 40px;
    line-height: 40px;
    width: 40px;
}

.bootstrap-datetimepicker-widget a[data-action]{
    padding: 2px 0;
}

.bootstrap-datetimepicker-widget table td span{  
    height: 40px;
    line-height: 40px;
}

.input-group-prepend {
    margin-right: -1px;
    background: aliceblue;
    border-bottom: solid #c1c1c1 1px;
}

.input-group-prepend>.input-group-text {
    background-color: transparent;
    border: none;
}

.input-group [data-toggle="datetimepicker"]{
    padding-left: 5px;
}

.tooltip {
  z-index: 100000000; 
}

.btn-tool {
  background: 0 0;
  color: #478cd1;
  font-size: .875rem;
  margin: -0.75rem 0;
  padding: 0.25rem 0.5rem;
  border: solid 1px #d4d4ff;
}

.custom-switch {
  padding-left: 3.5rem;
  padding-top: 5px;
}

.kotak-penghasilan{
  border: solid #a3a1a1 2px;
  min-height: 75px;
}
.no-border{
  border : none!important;
}
.border-top{
  border-top: solid rgb(209 209 209) 1px;
  padding-top: 10px;
}
.swal-text {
  text-align: center;
}

.history-box{
    position: relative;
    display: block;
    padding: 0.75rem;
    margin: 2px;
    background-color: #c3e6cb;
}

.history-date{
    color: white !important;
    padding: 3px;
    background: #6e94eb;
}

div.dataTables_wrapper div.dataTables_length select {
  min-width: 40px;
}

.btn-success{
  color:#fff !important;
}

.datepicker.dropdown-menu {
  z-index:9999;
}

.sub-menu{
  margin-left : 10px
}

.page-tool {
  text-align: right;
}

.thead-green{
  background-color: #daf1da;
}

.div-left-15px{
    padding-left: 15px !important;
}


textarea:read-only{
  background-color: #e9ecef;
}

.tittle-objective{
  margin-top: 15px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.p-10px{
  padding: 10px!important;
}

.p-5px{
  padding: 5px!important;
}

.dataTables_wrapper > .row{
  margin: 0px !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before, table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before{
  display: inline-block!important;
  position: initial!important;
  margin-right: 5px;
}
.content-header{
  padding : 5px 0.5rem
}
.content-wrapper > .content{
  padding-left : 20px;
}
.btn-table{
  border-radius: 15px;
  line-height: 1;
  margin-left: 5px;
  z-index: 99999;
}
.pagination{
  margin: 0px !important;
}
.navbar-brand{
  color: white;
}
.nav-link{
  color: white;
}
.nav-link:hover{
  color: rgb(226, 226, 226);
}
.nav-item:hover{
  color: rgb(218, 217, 221);
}
label:not(.form-check-label):not(.custom-file-label){
  font-weight: 400;
}
.is-invalid > .select2-container >.selection >.select2-selection{
  border-color: red;
}
.a-class{
    text-decoration : none!important;
    color           : currentColor!important;
}
.navy{
  color: #1a72bb;
}
.info-box > .bg-blue{
  background-color: #568abd!important;
}
.info-box > .bg-orange > i{
  color: white!important;
}
.notfound{
    color: #d37370;
    font-size: 150px;
    margin-top: 100px;
}
.minh-65{
    min-height: 65vh!important;
}
.swal2-styled.swal2-cancel {
    background-color: #959996 !important;
}
.div-tabel{
    border-top: solid rgb(209 209 209) 1px;
    border-bottom: solid rgb(209 209 209) 1px;
    min-height: 200px;
    overflow: auto;
}
.text-italic{
  font-style: italic;
  text-decoration: none;
}
.min-200px{
  min-width : 200px
}
.padding-10{
  padding: 10px;
}
.padding-top-10{
  padding-top: 10px!important;
}
.padding-bottom-10{
  padding-bottom: 10px!important;
}
.is-invalid > .mx-input-wrapper {
  position: relative;
  border: solid red 1px;
  border-radius: 4px;
}
.select2-container .select2-selection--single .select2-selection__rendered{
  padding-left: 0px!important;
}

.a-text{
  color: inherit;
  text-decoration: none;
}
.a-text:hover{
  color: inherit;
  text-decoration: none;
}